import React from 'react'
import {
  Segment,
  Container,
  Image,
  Icon,
  Header,
  Button,
} from 'semantic-ui-react'
import Layout from '../components/layout'
import PageHeader from '../components/PageHeader'
import { FormattedMessage } from 'gatsby-plugin-intl'

import './index.css'

const SupportPrimary = () => (
  <Segment as="section" vertical>
    <Container>
      <Image
        src="https://media.giphy.com/media/phJ6eMRFYI6CQ/giphy.gif"
        centered
        rounded
      />
    </Container>
  </Segment>
)

const Support = ({ intl }) => (
  <Layout>
    <div className="SupportPage">
      <PageHeader>
        <Header inverted as="h1">
          <FormattedMessage id="title-support" />
        </Header>
        <p>
          <FormattedMessage id="subheader-support" />
        </p>
        <Button
          secondary
          size="big"
          as="a"
          href="mailto:support@mangove.ai?subject=Contact"
        >
          <Icon name="mail" /> <FormattedMessage id="button-contact" />
        </Button>
      </PageHeader>
      <SupportPrimary />
    </div>
  </Layout>
)

export default Support
